import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import CeramtecPage from '../ui/pages/CeramtecPage'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/de.json'

const Ceramtec = props => (
  <>
    <HelmetComponent
      title={metaTags.ceramtecTitle}
      description={metaTags.ceramtec}
      page="de/ceramtec"
    />
    <AppWrapper {...props} lang="de">
      <CeramtecPage />
    </AppWrapper>
  </>
)

export default Ceramtec
